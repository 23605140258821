<template>
  <div id="loginPage">
    <div class="loginLogo">
      <div v-show="contentShow" class="contentBox">
        <div class="contentLeft">
          <img src="../../assets/login/bagleft.png" alt="" />
        </div>
        <div class="contentRight">
          <div class="navTab">
            <el-menu :default-active="activeIndex" active-text-color="#AC4B38" class="el-menu-demo" mode="horizontal"
              @select="handleSelect">
              <el-menu-item index="2">短信登录</el-menu-item>
              <el-menu-item index="3">账号登录</el-menu-item>
            </el-menu>
          </div>
          <div v-show="activeShow1" class="phoneInp">
            <p v-show="inpTrue" style="color: red; font-size: 12px">
              账号密码错误
            </p>
            <el-input v-model="phoneInp" placeholder="请输入账号或手机号"></el-input>
            <el-input :type="passType" v-model="phonePass" placeholder="请输入密码">
              <i slot="suffix" @click="showPass()" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 100%;
                ">
                <img v-show="srcImg" style="width: 24px; height: 24px" src="../../assets/img/loginImg/yan.png" alt="" />
                <img v-show="!srcImg" style="width: 24px; height: 24px" src="../../assets/img/loginImg/zheng.png"
                  alt="" />
              </i>
            </el-input>
            <p class="checkP">
              <el-checkbox v-model="checked" @change="checkedtrue">记住账户
              </el-checkbox>
              <span style="float: right; color: #ac4b38" @click="retriePassword"><a href="#"
                  style="color: #ac4b38">忘记密码？</a></span>
            </p>
          </div>
          <div v-show="!activeShow1" class="verificationInp">
            <p style="color: #ccc; font-size: 12px">
              未注册的手机号验证后自动创建账号
            </p>
            <el-input v-model="verInp" @input="phoneInput" placeholder="请输入手机号"></el-input>
            <div class="inpBtn">
              <el-input v-model="verPass" style="" placeholder="请输入6位验证码">
                <el-button v-show="verPassBtnShow" slot="suffix" :disabled="disableVer" :loading="loadings"
                  @click="getCode" :style="{
                    marginLeft: marginLeft,
                    marginTop: marginTop,
                    background: background,
                    color: color,
                    border: border,
                  }">
                  <span> {{ verText }}</span>
                </el-button>
                <el-button v-show="!verPassBtnShow" slot="suffix" :disabled="disableVer" :loading="loadings"
                  @click="getCode" :style="{
                    marginLeft: marginLeft,
                    marginTop: marginTop,
                    background: background,
                    color: color,
                    border: border,
                  }">

                  <span>{{ count }}秒后可重发</span>
                </el-button>
              </el-input>
            </div>
          </div>
          <div class="loginBtn">
            <el-button @click="submit" style="
                width: 100%;
                height: 50px;
                font-size: 18px;
                background: #ac4b38;
                color: #f1f1f1;
                border: none;
              ">登录
            </el-button>
          </div>
          <div class="otherMode">
            <hr />
            <span>其他登陆方式</span>
            <hr />
            <div class="loginOhter">
              <dl @click="wechat_login">
                <dt><img src="../../assets/img/loginImg/微信.png" alt="" /></dt>
                <dd>微信登录</dd>
              </dl>
              <dl @click="QQ_login">
                <a
                  href="https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=102026375&response_type=token&scope=all&redirect_uri=https%3A%2F%2Fwww.zhongyigen.com%2Flogin">
                  <dt><img src="../../assets/personal/编组2.png" alt="" /></dt>
                  <dd style="color: #ccc">QQ登录</dd>
                </a>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!contentShow" class="contentBoxTwo">
        <p class="retrieveTitle">找回密码</p>
        <p>
          <el-input v-model="retriePhonenum" @input="retriephoneInput" placeholder="请输入手机号"
            style="margin-top: 20px; width: 380px"></el-input>
        </p>
        <p>
          <el-input v-model="retrieimgNum" placeholder="请输入图形验证码" style="margin-top: 20px; width: 380px">
            <i slot="suffix"><img @click="shuaxin" style="width: 120px; height: 40px; padding-top: 5px" :src="imgsrc"
                alt="" /></i>
          </el-input>
        </p>
        <p>
          <el-input v-model="retrieSixNum" placeholder="请输入6位验证码" style="margin-top: 20px; width: 380px">
            <el-button v-show="retrievePassBtnShow" slot="suffix" :disabled="retriedisableVer" :loading="loadings1"
              @click="retrievegetCode" :style="{
                marginLeft: marginLeft,
                marginTop: marginTop,
                background: retrievebackground,
                color: color,
                border: border,
              }">
              {{ retrieveverText }}
            </el-button>
            <el-button v-show="!retrievePassBtnShow" slot="suffix" :disabled="retriedisableVer" :loading="loadings1"
              @click="retrievegetCode" :style="{
                marginLeft: marginLeft,
                marginTop: marginTop,
                background: retrievebackground,
                color: color,
                border: border,
              }">
              {{ retrievecount }}秒后可重发
            </el-button>
          </el-input>
        </p>
        <p>
          <el-input v-model="retriePassNum" :type="retriepassType" placeholder="请设置6~20位英文/数字密码"
            style="margin-top: 20px; width: 380px">
            <i slot="suffix" @click="retrieshowPass()" style="
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 100%;
              ">
              <img v-show="retriesrcImg" style="width: 24px; height: 24px" src="../../assets/img/loginImg/yan.png"
                alt="" />
              <img v-show="!retriesrcImg" style="width: 24px; height: 24px" src="../../assets/img/loginImg/zheng.png"
                alt="" />
            </i>
          </el-input>
        </p>
        <p>
          <el-button class="resetPassword" @click="resetPass">重置密码
          </el-button>
        </p>
        <p style="margin-top: 30px">
          <a href="#" @click="retriePassword" style="color: #ccc">返回登录</a>
        </p>
      </div>
      <bindPhone v-show="bindPhone" />
    </div>
    <!-- <div class="loginbag">
      <p class="footText">北京神黄科技股份有限公司 | 苏ICP17033601号-11</p>
    </div> -->
    <Vcode :show="errorVerShow" @success="success" @close="close" />
    <div class="homebotText">
      <div class="textLeft">
        <p class="chudai">
          <img src="../../assets/img/home/icon_网站logo.png" alt="" />
        </p>
        <p class="zuofei"><span>神黄科技</span>&nbsp;&nbsp;版权所有</p>
        <p class="saiwen">
          <span><a target="_blank" href="https://www.haiweikexin.com/">南京海维可信</a></span>&nbsp;&nbsp;技术支持
        </p>
        <p class="jieke">
          Copyright © 2012-2022 ｜
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" el="nofollow">苏ICP备17033601号-11</a>
          ｜ 互联网药品信息服务资格证：(京)-非经营性-2015-0164
        </p>
      </div>
      <div class="qrcode">
        <dl>
          <dt>
            <img src="../../assets/img/footerassembly/xiazai2x.png" alt="" />
          </dt>
          <dd>下载APP</dd>
        </dl>
        <dl>
          <dt>
            <img src="../../assets/img/footerassembly/weixin.png" alt="" />
          </dt>
          <dd>微信号</dd>
        </dl>
        <dl>
          <dt>
            <img src="../../assets/img/footerassembly/kefu.png" alt="" />
          </dt>
          <dd>客服</dd>
        </dl>
      </div>
    </div>
    <div class="lodings" v-show="lodingShow">
      <img src="../../assets/img/chinescommunity/da.gif" alt="">
    </div>
  </div>
</template>
<script>
//绑定手机号弹窗
import bindPhone from "@/components/bindPhone/bindPhone";
import { loginApi, verApi, getLabelApi } from "@/util/axiosHttp";
import store from "@/store/store";
import axios from "@/util/http";

import Vcode from "vue-puzzle-vcode";

export default {
  components: {
    bindPhone, Vcode
  },
  metaInfo() {
    return {
      title: '中医智库 - 让中医药为人类健康事业做出更大贡献',
    };
  },
  data() {
    return {
      activeIndex: "2",
      contentShow: true,
      lodingShow: false,
      activeShow1: false,
      bindPhone: false,
      errorVerShow: false,
      phoneInp: "",
      phonePass: "",
      verInp: "",
      verPass: "",
      checked: false,
      checked1: false,
      srcImg: true,
      retriesrcImg: true,
      passType: "password",
      retriepassType: "password",
      inpTrue: false,
      disableVer: true,
      marginLeft: "10px",
      marginTop: "5px",
      background: "#ccc",
      color: "#fff",
      border: "none",
      verText: "获取验证码",
      count: "",
      verPassBtnShow: true,
      retrievePassBtnShow: true,
      retrievebackground: "#ccc",
      retrieveverText: "获取验证码",
      retriedisableVer: true,
      loadings1: false,
      loadings: false,
      retrievecount: "",
      retriePhonenum: "",
      retrieimgNum: "",
      retrieSixNum: "",
      retriePassNum: "",
      imgsrc: "",
      openId: "",
      unionid: "",
      nickname: "",
      headimgurl: "",
      clickCount: 0,
      startTime: null,
      verTime: '',
      verCount: 0,
      vCodeErrorCount: 0,
    };
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
    this.getCookie();
    QC.Login({
      btnId: "qqLoginBtn",
    });
    let trues = QC.Login.check();
    if (trues) {
      let that = this;
      QC.Login.getMe(function (openId, accessToken) {
        that.openId = openId;
      });
      QC.Login.getMe((unionId) => {
        that.unionid = unionId;
      });
      QC.api("get_user_info").success((res) => {
        that.headimgurl = res.data.figureurl_qq;
        that.nickname = res.data.nickname;
      });

      setTimeout(function () {
        let data = {
          udid: "web",
          openid: that.openId,
          unionid: that.unionid,
          nickname: that.nickname,
          avatar: that.headimgurl,
          login_type: "qq",
          platform_type: "3",
        };
        //console.log(data);
        axios.post("users/author_login", data).then((res) => {
          if (res.data.msg == "未绑定手机号") {
            store.state.bindPhoneShow = true;
          } else {
            localStorage.setItem("login", JSON.stringify(res.data.data));
          }
        });

        that.$router.push({
          name: "home",
        });
      }, 1000);
    }

    let accToken = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).access_token ? JSON.parse(localStorage.getItem('login')).access_token : ""
    let userId = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""
    if (accToken) {
      this.$router.push({
        name: "home",
      });
    }
  },
  beforeDestroy() {
    localStorage.setItem(this.verInp + '_count', this.verCount)
    localStorage.setItem(this.verInp + '_time', this.verTime)
    localStorage.setItem('vCodeErrorCount', this.vCodeErrorCount)

  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
    localStorage.setItem(this.verInp + '_count', this.verCount)
    localStorage.setItem(this.verInp + '_time', this.verTime)
    localStorage.setItem('vCodeErrorCount', this.vCodeErrorCount)

  },
  beforeunloadHandler() {
    localStorage.setItem(this.verInp + '_count', this.verCount)
    localStorage.setItem(this.verInp + '_time', this.verTime)
    localStorage.setItem('vCodeErrorCount', this.vCodeErrorCount)

  },
  unloadHandler() {
    localStorage.setItem(this.verInp + '_count', this.verCount)
    localStorage.setItem(this.verInp + '_time', this.verTime)
    localStorage.setItem('vCodeErrorCount', this.vCodeErrorCount)

  },
  methods: {
    // 短信或账户登录显示
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      if (key == 2) {
        this.activeShow1 = false;
      } else {
        this.activeShow1 = true;
      }
    },
    //显示密码
    showPass() {
      this.srcImg = !this.srcImg;
      this.passType = this.srcImg ? "password" : "";
    },
    //找回密码时显示密码
    retrieshowPass() {
      this.retriesrcImg = !this.retriesrcImg;
      this.retriepassType = this.retriesrcImg ? "password" : "";
    },
    //记住密码
    checkedtrue() {
      ////console.log(this.checked)
      // 设置cookie
      if (this.checked) {
        this.setCookie();
      } else {
        this.clearCookie();
      }
    },
    // 设置cookie
    setCookie() {
      const exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 7); // 保存的天数
      window.document.cookie =
        "username" +
        "=" +
        this.phoneInp +
        ";path=/;expires=" +
        exdate.toGMTString();
      window.document.cookie =
        "password" +
        "=" +
        this.phonePass +
        ";path=/;expires=" +
        exdate.toGMTString();
      window.document.cookie =
        "checked" +
        "=" +
        this.checked +
        ";path=/;expires=" +
        exdate.toGMTString();
    },
    // 读取cookie
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split("; ");
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split("=");
          //console.log(arr[2])
          if (arr2[0] === "username") {
            this.phoneInp = arr2[1];
          } else if (arr2[0] === "password") {
            this.phonePass = arr2[1];
          } else if (arr2[0] === "checked") {
            this.checked = Boolean(arr2[1]);
          }
        }
      }
    },
    // 清除cookie
    clearCookie() {
      this.setCookie("", "");
    },
    //输入手机号时改变
    phoneInput() {
      this.background = "#AC4B38";
      this.disableVer = false;
    },
    //找回密码输入手机号时改变
    retriephoneInput() {
      this.retrievebackground = "#AC4B38";
      this.retriedisableVer = false;
    },
    //获取验证码
    getCode() {


      let phoneInpText = this.verInp;
      this.verCount = localStorage.getItem(phoneInpText + '_count') ? localStorage.getItem(phoneInpText + '_count') : this.verCount
      this.verTime = localStorage.getItem(phoneInpText + '_time') ? localStorage.getItem(phoneInpText + '_time') : this.verTime
      console.log()
      //首次点击
      console.log(Number(this.verCount), 'this.verCount')
      if (Number(this.verCount) == 0) {
        console.log('首次')
        this.verTime = new Date()
        let phoneInpText = this.verInp;
        let reg = /^1[3-9][0-9]{9}$/;
        const TIME_COUNT = 60;
        if (reg.test(phoneInpText)) {
          //console.log('手机号验证通过')
          this.count = TIME_COUNT;
          this.verPassBtnShow = false;
          //console.log(phoneInpText)
          let data = {};
          data.phone = phoneInpText;
          data.area_code = "86";
          data.type = "login";
          data.platform = "pc"
          verApi(data)
            .then((res) => {
              if (res.data.status_code == 200) {
                if (res.data.data.status_code != 200) {
                  alert(res.data.data.msg)
                  this.verPassBtnShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                  this.disableVer = false;
                  this.background = "#AC4B38";
                  this.verText = "获取验证码";
                } else {
                  this.loadings = false
                  this.verCount++; // 点击时递增
                  localStorage.setItem(this.verInp + '_count', this.verCount)
                  localStorage.setItem(this.verInp + '_time', this.verTime)

                }
              } else {
                alert(res.data.msg)
                this.verPassBtnShow = true;
                clearInterval(this.timer);
                this.timer = null;
                this.disableVer = false;
                this.background = "#AC4B38";
                this.verText = "获取验证码";
              }
            })
            .catch((err) => {
            });
          this.timer = setInterval(() => {
            this.disableVer = true;
            this.background = "#ccc";
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.verPassBtnShow = true;
              clearInterval(this.timer);
              this.timer = null;
              this.disableVer = false;
              this.background = "#AC4B38";
              this.verText = "重新获取";
            }
          }, 1000);
        } else {
          alert("手机号错误请重新输入");
          this.verInp = "";
        }
      }
      else {
        const now = Date.now(); // 获取当前时间的时间戳
        if (this.verTime) {
          const givenTimestamp = new Date(this.verTime).getTime(); // 转为时间戳
          const oneMinuteLater = givenTimestamp + 60 * 60 * 1000; // 加上1分钟的毫秒数
          const currentTimestamp = Date.now(); // 获取当前时间的时间戳
          if (currentTimestamp > oneMinuteLater) { // 判断是否超过 1 小时
            localStorage.setItem(this.verInp + '_time', currentTimestamp)
            this.verCount = Number(0)
            localStorage.setItem(this.verInp + '_count', this.verCount)
          } else {
            console.log("未超过 1 小时，时间未重置。");
          }
        }
        //并非首次点击
        this.verCount++; // 点击时递增
        if (Number(this.verCount) < 3) {
          console.log('2')
          // this.loadings = true
          let phoneInpText = this.verInp;
          let reg = /^1[3-9][0-9]{9}$/;
          const TIME_COUNT = 60;
          if (reg.test(phoneInpText)) {
            //console.log('手机号验证通过')
            this.count = TIME_COUNT;
            this.verPassBtnShow = false;
            //console.log(phoneInpText)
            let data = {};
            data.phone = phoneInpText;
            data.area_code = "86";
            data.type = "login";
            data.platform = "pc"
            verApi(data)
              .then((res) => {
                console.log(res.data.data.status_code)
                if (res.data.status_code == 200) {
                  if (res.data.data.status_code != 200) {
                    alert(res.data.data.msg)
                    this.verPassBtnShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    this.disableVer = false;
                    this.background = "#AC4B38";
                    this.verText = "获取验证码";
                  } else {
                    this.loadings = false
                    this.verCount++; // 点击时递增
                    localStorage.setItem(this.verInp + '_count', this.verCount)
                  }
                } else {
                  alert(res.data.msg)
                  this.verPassBtnShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                  this.disableVer = false;
                  this.background = "#AC4B38";
                  this.verText = "获取验证码";
                }
              })
              .catch((err) => {
              });
            this.timer = setInterval(() => {
              this.disableVer = true;
              this.background = "#ccc";
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.verPassBtnShow = true;
                clearInterval(this.timer);
                this.timer = null;
                this.disableVer = false;
                this.background = "#AC4B38";
                this.verText = "重新获取";
              }
            }, 1000);
          } else {
            alert("手机号错误请重新输入");
            this.verInp = "";
          }
        }
        else if (Number(this.verCount) >= 3 && Number(this.verCount) < 10) {
          this.errorVerShow = true
        }
        else if (Number(this.verCount) == 10) {
          alert('操作频繁请稍后再试')

          return false;
        }
      }
    },
    // 用户通过了验证
    success() {
      this.errorVerShow = false
      let phoneInpText = this.verInp;
      let reg = /^1[3-9][0-9]{9}$/;
      const TIME_COUNT = 60;
      if (reg.test(phoneInpText)) {
        //console.log('手机号验证通过')
        this.count = TIME_COUNT;
        this.verPassBtnShow = false;
        //console.log(phoneInpText)
        let data = {};
        data.phone = phoneInpText;
        data.area_code = "86";
        data.type = "login";
        data.platform = "pc"
        verApi(data)
          .then((res) => {
            if (res.data.status_code == 200) {

              if (res.data.data.status_code != 200) {
                alert(res.data.data.msg)
                this.verPassBtnShow = true;
                clearInterval(this.timer);
                this.timer = null;
                this.disableVer = false;
                this.background = "#AC4B38";
                this.verText = "获取验证码";
              } else {
                this.loadings = false
                this.verCount++; // 点击时递增
                localStorage.setItem(this.verInp + '_count', this.verCount)
                localStorage.setItem(this.verInp + '_time', this.verTime)
              }
            } else {
              alert(res.data.msg)

              this.verPassBtnShow = true;
              clearInterval(this.timer);
              this.timer = null;
              this.disableVer = false;
              this.background = "#AC4B38";
              this.verText = "获取验证码";
            }
          })
          .catch((err) => {
          });
        this.timer = setInterval(() => {
          this.disableVer = true;
          this.background = "#ccc";
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.verPassBtnShow = true;
            clearInterval(this.timer);
            this.timer = null;
            this.disableVer = false;
            this.background = "#AC4B38";
            this.verText = "重新获取";
          }
        }, 1000);
      } else {
        alert("手机号错误请重新输入");
        this.verInp = "";
      }
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.vCodeErrorCount = this.vCodeErrorCount + 1
      localStorage.setItem('vCodeErrorCount', this.vCodeErrorCount)
      if (this.vCodeErrorCount == 5) {
        alert('超出限制请稍后再试')
        setTimeout(() => {
          this.vCodeErrorCount = 0
        }, 60000)
      }
    },
    //找回密码的获取验证码
    retrievegetCode() {
      this.loadings1 = true
      if (this.retrieveverText == "重新获取") {
        this.shuaxin();
      }
      let phoneInpText = this.retriePhonenum;
      let reg = /^1[3-9][0-9]{9}$/;
      const TIME_COUNT = 60;
      if (reg.test(phoneInpText)) {
        //console.log('手机号验证通过')
        this.retrievecount = TIME_COUNT;
        this.retrievePassBtnShow = false;
        this.retriedisableVer = true;
        this.retrievebackground = "#ccc";

        let data = {};
        data.phone = phoneInpText;
        data.area_code = "86";
        data.type = "login";
        data.platform = "pc"
        verApi(data)
          .then((res) => {
            //console.log(res)
            console.log(res)
            if (res.code == 200) {
              if (res.data.status_code == 200) {

                if (res.data.data.status_code != 200) {
                  alert(res.data.data.msg)
                  this.verPassBtnShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                  this.disableVer = false;
                  this.background = "#AC4B38";
                  this.verText = "获取验证码";
                } else {
                  this.loadings1 = false

                }
              } else {
                alert(res.data.msg)
                this.retrievePassBtnShow = true;
                clearInterval(this.timer);
                this.timer = null;
                this.retriedisableVer = false;
                this.retrievebackground = "#AC4B38";
                this.retrieveverText = "重新获取";
              }
            } else {

            }
          })
          .catch((err) => {
            //console.log(err)
          });
        this.timer = setInterval(() => {
          if (this.retrievecount > 0 && this.retrievecount <= TIME_COUNT) {
            this.retrievecount--;
          } else {
            this.retrievePassBtnShow = true;
            clearInterval(this.timer);
            this.timer = null;
            this.retriedisableVer = false;
            this.retrievebackground = "#AC4B38";
            this.retrieveverText = "重新获取";
          }
        }, 1000);
      } else {
        alert("手机号错误请重新输入");
        this.retriePhonenum = "";
      }
    },
    //重置密码
    resetPass() {
      if (this.retriePhonenum == "" && this.retriePhonenum == null) {
        alert("请输入手机号");
      } else if (this.retrieimgNum == "" && this.retrieimgNum == null) {
        alert("请输入图形验证码");
      } else if (this.retrieSixNum == "" && this.retrieSixNum == null) {
        alert("请输入验证码");
      } else if (this.retriePassNum == "" && this.retriePassNum == null) {
        alert("请输入密码");
      } else {
        let data = {};
        data.phone = this.retriePhonenum;
        data.area_code = "86";
        data.captcha_code = this.retrieimgNum;
        data.reset_password = this.retriePassNum;
        data.sms_code = this.retrieSixNum;
        //console.log(data)
        this.$axios
          .post("users/find_pwd", data)
          .then((res) => {
            //console.log(res)
            this.contentShow = !this.contentShow;
          })
          .catch((err) => {
            this.contentShow = !this.contentShow;
            //console.log(err)
          });
      }
    },
    // 找回密码与返回登陆
    retriePassword() {
      this.contentShow = !this.contentShow;
      //获取图形验证码
      this.$axios
        .get("get_captcha")
        .then((res) => {
          if (res.status == 200) {
            this.imgsrc = res.data;
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    },
    shuaxin() {
      //获取图形验证码
      this.$axios
        .get("get_captcha")
        .then((res) => {
          if (res.status == 200) {
            this.imgsrc = res.data;
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    },
    // 登录
    submit() {
      this.lodingShow = true
      let data = {};
      data.udid = "web";
      data.area_code = "86";
      data.terminal = "web";
      data.platform_type = "3";
      data.login_type = parseInt(this.activeIndex);
      if (this.activeIndex == "3") {
        data.phone = this.phoneInp;
        data.password = this.phonePass;
      } else if (this.activeIndex == "2") {
        data.phone = this.verInp;
        data.verification_code = this.verPass;
      }
      if (data.phone.length == 11) {
        loginApi(data)
          .then((res) => {
            localStorage.removeItem(this.verInp + '_count')
            localStorage.removeItem(this.verInp + '_time')
            localStorage.removeItem('vCodeErrorCount')
            localStorage.setItem("login", JSON.stringify(res.data.data));
            this.$store.commit("setusername", JSON.stringify(res.data.data));
            localStorage.setItem("loginPhone", JSON.stringify(data.phone));
            if (res.data.status_code == 200) {
              getLabelApi(
                res.data.data.user_id,
                res.data.data.access_token
              ).then((res) => {
                if (res.data.status_code == 200) {
                  if (res.data.data.is_set_label == "0") {
                    this.$router.push({
                      name: "selectIdentity",
                    });
                  } else {
                    if (this.$store.state.roued) {
                      if (this.$store.state.query) {
                        this.$router.push({
                          path: this.$store.state.roued,
                          query: this.$store.state.query,
                        });
                      } else if (this.$store.state.roued) {
                        this.$router.push({
                          path: this.$store.state.roued,
                        });
                      } else {
                        this.$router.push({
                          name: "home",
                        });
                      }
                    } else {
                      this.$router.push({
                        name: "home",
                      });
                    }
                  }
                  setTimeout(() => {
                    this.lodingShow = false
                    if (location.href.indexOf("#") == -1) {
                      //在当前页面地址加入"#"，使下次不再进入此判断
                      location.href = location.href + "#";
                      location.reload();
                    }
                  }, 1000)
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            alert(err)
          });
      } else {
        this.$message.error("手机号错误");
      }
    },
    //微信登陆
    wechat_login() {
      // appid
      let appid = "wx5c64d9de3bfd1a49";
      let redirectUrl = window.location.href;
      redirectUrl = redirectUrl.split("lo")[0];
      //console.log(redirectUrl)
      redirectUrl = encodeURIComponent(redirectUrl);
      let url =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        appid +
        "&redirect_uri=" +
        redirectUrl +
        "&response_type=code&scope=snsapi_login&state=type#wechat_redirect";
      window.location.href = url;
    },
    QQ_login() {
      let appId = "101935018";
      let appKey = "317a54597896084c770ce194ffd3cc20";
    },
  },
};
</script>

<style lang="scss" scoped>
#loginPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url("../../assets/login/bagg.png") no-repeat;
  background-size: 100% 100%;

  .lodings {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    img {
      width: 150px;
      height: 150px;
    }
  }

  .loginLogo {
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }

    .loginTitle {
      position: absolute;
      margin-top: 70px;
      text-align: center;
      color: #fff;
      font-size: 36px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      align-items: center;
    }

    .contentBox {
      position: absolute;
      margin-top: 100px;
      width: 808px;
      height: 548px;
      box-shadow: 10px 10px 20px 0px rgba(178, 93, 67, 0.12);
      border-radius: 10px;
      display: flex;

      .contentLeft {
        width: 352px;
        height: 100%;
      }

      .contentRight {
        border-radius: 0 10px 10px 0;
        flex: 1;
        background: #fff;

        .navTab {
          width: 100%;
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;

          .el-menu.el-menu--horizontal {
            border-bottom: 0;
          }

          .el-menu-item {
            font-size: 20px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
          }
        }

        .phoneInp {
          height: 200px;
          padding-left: 60px;
          padding-right: 60px;
          box-sizing: border-box;

          .el-input {
            margin-top: 15px;
            box-shadow: inset 0px -1px 0px 0px #f1f1f1;
            border-radius: 4px;

            /deep/ .el-input__inner {
              height: 50px !important;
            }

            /deep/ .el-input__inner:focus {
              border: none;
            }
          }

          .checkP {
            margin-top: 5px;
            font-size: 14px;

            /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
              color: #ac4b38;
            }

            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #ac4b38;
              border-color: #ac4b38;
            }
          }
        }

        .verificationInp {
          height: 200px;
          padding-left: 60px;
          padding-right: 60px;
          box-sizing: border-box;

          .el-input {
            margin-top: 15px;
            box-shadow: inset 0px -1px 0px 0px #f1f1f1;
            border-radius: 4px;

            /deep/ .el-input__inner {
              height: 50px !important;
            }

            /deep/ .el-input__inner:focus {
              border: none;
            }
          }

          .inpBtn {
            height: 60px;

            .el-button {
              width: 110px;
              height: 40px;
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;

              span {}
            }
          }

          .checkP {
            margin-top: 10px;
            font-size: 14px;

            /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
              color: #ac4b38;
            }

            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #ac4b38;
              border-color: #ac4b38;
            }
          }
        }

        .loginBtn {
          padding-left: 60px;
          padding-right: 60px;
        }

        .otherMode {
          padding-left: 50px;
          padding-right: 50px;
          box-sizing: border-box;
          text-align: center;
          margin-top: 20px;
          color: #ccc;

          hr {
            width: 100px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid #ccc;
          }

          span {
            color: #ccc;
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 14px;
          }

          .loginOhter {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            dl {
              margin-right: 15px;
            }
          }

          dl dt img {
            margin-top: 10px;
            width: 40px;
            height: 40px;
          }

          dl dd {
            font-size: 14px;
          }
        }
      }
    }

    .contentBoxTwo {
      position: absolute;
      margin-top: 150px;
      width: 808px;
      height: 548px;
      box-shadow: 10px 10px 20px 0px rgba(178, 93, 67, 0.12);
      border-radius: 10px;
      display: flex;
      background: #fff;
      flex-direction: column;
      align-items: center;

      .retrieveTitle {
        margin-top: 53px;
        font-size: 27px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        color: #b25d43;
      }

      p {
        height: 60px;

        .el-input {
          width: 380px;

          /deep/ .el-input__inner {
            height: 50px !important;
          }
        }

        .resetPassword {
          width: 380px;
          margin-top: 30px;
          background: #ac4b38;
          color: #fff;
          height: 50px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  }

  .loginbag {
    height: 55%;
    text-align: center;

    .footText {
      font-size: 14px;
      color: #b25d43;
      font-family: PingFang-SC-Regular, PingFang-SC;
      position: relative;
      top: 460px;
    }
  }

  .homebotText {
    // position: absolute;
    margin-top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .textLeft {
      margin-right: 32px;
      box-sizing: border-box;
      width: 886px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: 25px;
      color: #444;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      .chudai {
        img {
          width: 140px;
          height: 50px;
        }
      }
    }

    .qrcode {
      display: flex;
      justify-content: center;
      align-items: center;

      dl {
        width: 130px;
        height: 140px;
        margin-left: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        dt {
          img {
            width: 120px;
            height: 120px;
          }
        }

        dd {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 23px;
        }
      }
    }
  }
}
</style>
