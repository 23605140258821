<template>
  <div class="bingPhoneBox">
    <div class="bingPhone">
      <p>绑定手机号</p>
      <p>
        <el-input v-model="Phonenum" @input="phoneInput" placeholder="请输入手机号"
          style="margin-top: 20px;width: 380px"></el-input>
      </p>
      <p>
        <el-input v-model="imgNum" placeholder="请输入图形验证码" style="margin-top: 20px;width: 380px">
          <i slot="suffix"><img style="width: 120px;height: 40px;padding-top: 5px" :src="imgsrc" alt=""></i>

        </el-input>
      </p>
      <p>
        <el-input v-model="sixNum" maxlength="6" placeholder="请输入6位验证码" style="margin-top: 20px;width: 380px">
          <el-button v-show="BtnShow" slot="suffix" :disabled="disableVer" @click="getCode"
            :style="{ marginLeft: marginLeft, marginTop: marginTop, background: background, color: color, border: border, }">
            {{ verText }}
          </el-button>
          <el-button v-show="!BtnShow" slot="suffix" :disabled="disableVer" @click="getCode"
            :style="{ marginLeft: marginLeft, marginTop: marginTop, background: background, color: color, border: border, }">
            {{ count }}秒后可重发
          </el-button>

        </el-input>
      </p>
      <p class="onok">
        <el-button @click="bindPhoneBtn">确定</el-button>
        <a @click="goback">返回</a>
      </p>
    </div>
  </div>
</template>

<script>
import { verApi } from "@/util/axiosHttp";

export default {
  data() {
    return {
      Phonenum: "",
      marginLeft: '10px',
      marginTop: '5px',
      background: "#ccc",
      color: "#fff",
      border: "none",
      verText: "获取验证码",
      count: "",
      sixNum: "",
      imgNum: "",
      BtnShow: true,
      disableVer: true,
      imgsrc: ""
    }
  },
  mounted() {
    this.getVerCode()
  },
  methods: {
    getVerCode() {
      //获取图形验证码
      this.$axios.get('get_captcha').then(res => {
        if (res.status == 200) {
          this.imgsrc = res.data
        }
      }).catch(err => {
      })
    },
    //输入手机号时改变
    phoneInput() {
      this.background = '#AC4B38';
      this.disableVer = false;
    },
    // //获取验证码
    // getCode(){
    //   let phoneInpText = this.Phonenum
    //   let reg = /^1[3-9][0-9]{9}$/;
    //   const TIME_COUNT = 60;
    //   if(reg.test(phoneInpText)){
    //     //////console.log('手机号验证通过')
    //     this.count = TIME_COUNT;
    //     this.verPassBtnShow = false
    //     this.disableVer = true;
    //     this.background = "#ccc";
    //     this.timer = setInterval(() => {
    //       if (this.count > 0 && this.count <= TIME_COUNT) {
    //         this.count--;
    //       } else {
    //         this.verPassBtnShow = true
    //         clearInterval(this.timer);
    //         this.timer = null;
    //         this.disableVer = false;
    //         this.background = "#AC4B38";
    //         this.verText = "重新获取"
    //       }
    //     }, 1000)
    //   }else{
    //     alert('手机号错误请重新输入')
    //     this.Phonenum=''
    //   }
    // },
    //获取验证码
    getCode() {
      let phoneInpText = this.Phonenum
      let reg = /^1[3-9][0-9]{9}$/;
      const TIME_COUNT = 60;
      if (reg.test(phoneInpText)) {
        this.count = TIME_COUNT;
        this.BtnShow = false
        let data = {}
        data.phone = phoneInpText
        data.area_code = '86'
        data.type = 'login'
        data.platform = "pc"
        verApi(data).then(res => { }).catch(err => { })
        this.timer = setInterval(() => {
          this.disableVer = true;
          this.background = "#ccc";
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.BtnShow = true
            clearInterval(this.timer);
            this.timer = null;
            this.disableVer = false;
            this.background = "#AC4B38";
            this.verText = "重新获取"
          }
        }, 1000)
      } else {
        alert('手机号错误请重新输入')
        this.verInp = ''
      }
    },
    // 确认绑定
    bindPhoneBtn() {
      let data = {
        unionid: this.$store.state.unionid,
        openid: this.$store.state.openid,
        phone: this.Phonenum,
        udid: "web",
        type: "wx",
        area_code: "86",
        terminal: "web",
        need_verify: "1",
        platform_type: "3",
        captcha_code: this.imgNum,
        sms_code: this.sixNum,
      }
      this.$axios.post('users/bind', data).then(res => {
        if (res.data.status_code != 200) {
          this.$message.error(res.data.msg);

        } else {
          localStorage.setItem('login', JSON.stringify(res.data.data))
          if (location.href.indexOf("#") == -1) {
            //在当前页面地址加入"#"，使下次不再进入此判断
            location.href = location.href + "#";
            location.reload();
          }
        }
      })
    },
    goback() {
      if (location.href.indexOf("#") == -1) {
        //在当前页面地址加入"#"，使下次不再进入此判断
        location.href = location.href + "#";
        location.reload();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bingPhoneBox {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;

  .bingPhone {
    margin-top: 100px;
    width: 708px;
    height: 490px;
    box-shadow: 10px 10px 20px 0px rgba(178, 93, 67, 0.12);
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    p:nth-child(1) {
      margin-top: 53px;
      font-size: 27px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #B25D43;
    }

    .onok {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
      box-sizing: border-box;

      .el-button {
        width: 380px;
        height: 50px;
        font-size: 18px;
        background: #AC4B38;
        color: #fff;
      }

      a {
        color: #cccccc;
        margin-top: 5px;
      }
    }

    /deep/.el-input__inner {
      height: 50px !important;
      border: none;
    }

    /deep/ .el-input__inner:focus {
      border: none;
    }
  }
}
</style>
